<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <b-form-group label="Upload Images">
              <b-media no-body>
                <b-media-aside>
                  <b-img rounded :src="getApiFile(null, true)" height="80"/>
                </b-media-aside>
                <b-media-body class="mt-75 ml-75">
                  <b-button variant="primary" size="sm" class="mb-75 mr-75" @click="selectFile()">Upload</b-button>
                  <b-form-file
                      plain
                      multiple
                      :hidden="true"
                      id="image_file"
                      name="image_file"
                      accept=".jpg, .jpeg, .png, .gif"
                      @input="inputImageRenderer()"/>
                  <b-card-text>Allowed JPG, GIF or PNG.</b-card-text>
                </b-media-body>
              </b-media>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-row>
      <b-table
          ref="refListTable"
          primary-key="id"
          empty-text="No record found"
          class="position-relative"
          :items="fetchList"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          responsive striped bordered hover show-empty>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon :id="`row-${data.item.id}-copy`" icon="EditIcon" size="16" @click="changeSortNumberShowModal(data.item)" class="mr-2"/>
            <remove-button-table :data="data" :re-fetch-data="reFetchData" remove-url="removeImage"></remove-button-table>
          </div>
        </template>

        <template #cell(image_type)="data">
          <b-badge pill :variant="`light-primary`" class="text-capitalize">
            Image
          </b-badge>
        </template>

        <template #cell(image_url)="data">
          <b-avatar
              :src="getApiFile(data.value)"
              :text="avatarText(data.item.name + ' ' + data.item.lastname)"
              :variant="`light-primary`"
              size="30px"/>
          <span>&#160;{{ data.value }}</span>
        </template>

        <template #cell(image_url)="data">
          <feather-icon icon="FileIcon" size="32" v-if="data.value.includes('.pdf')"/>
          <b-img :src="getApiFile(data.value)" fluid width="100" height="100" rounded v-if="!data.value.includes('.pdf')"/>
          <span>&#160;{{ data.value }}</span>
        </template>

      </b-table>
    </b-row>

    <Overlay :busy="busy"></Overlay>

    <b-modal size="lg" v-model="changeSortNumberModalShow" ref="change-status-modal" title="Project Image" ok-title="Save" cancel-title="Cancel" cancel-variant="outline-secondary" @ok="handleChangeSortNumberModal" centered no-close-on-backdrop>
      <b-form @submit.stop.prevent="submitChangeSortNumberModal" ref="refFormObserver">

        <b-form-group label="* Sort Number">
          <b-form-input trim placeholder="Sort Number" v-model="change_order_number"/>
        </b-form-group>

        <b-form-group label="Spaces">
          <v-select v-model="change_spaces_id" :options="spaceOptions" :reduce="val => val.value" :clearable="true" multiple="multiple"/>
        </b-form-group>

        <b-form-group label="Floor Materials">
          <v-select v-model="change_floor_material_id" :options="floorMaterialOptions" :reduce="val => val.value" :clearable="true" multiple="multiple"/>
        </b-form-group>

        <b-form-group label="Features">
          <v-select v-model="change_features_id" :options="featureOptions" :reduce="val => val.value" :clearable="true" multiple="multiple"/>
        </b-form-group>

      </b-form>
    </b-modal>

  </b-card>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import Overlay from "@/components/Overlay.vue";
import router from '@/router'
import store from "@/store"
import storeModule from "@/views/cms/project/store"
import {email, required} from "@core/utils/validations/validations";
import {toastMessage} from "@core/utils/utils"
import {onUnmounted, ref} from '@vue/composition-api'
import {useToast} from "vue-toastification/composition";
import {BAvatar, BBadge, BButton, BCard, BCardText, BCol, BForm, BFormFile, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTags, BImg, BMedia, BMediaAside, BMediaBody, BRow, BTable} from "bootstrap-vue";
import {avatarText, getApiFile} from "@core/utils/filter";
import vSelect from 'vue-select'
import languageModule from "@/views/cms/language/store";
import IdColumnTable from "@/components/Table/IdColumnTable.vue";
import RemoveButtonTable from "@/components/Table/RemoveButtonTable.vue";
import useList from "@/views/cms/project/useImageList";
import Vue from "vue";

export default {
  components: {
    BFormTags,
    BFormInput,
    BAvatar,
    BBadge,
    BTable,
    BFormInvalidFeedback,
    BImg,
    BMediaAside,
    BCardText,
    BFormFile,
    BMediaBody,
    BMedia,
    BFormGroup,
    BCol,
    BRow,
    BCard,
    BForm,
    BButton,

    vSelect,
    Overlay,
    ValidationProvider,
    ValidationObserver,
    RemoveButtonTable,
    IdColumnTable,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_LANGUAGE_MODULE_NAME = 'store-language'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_LANGUAGE_MODULE_NAME, languageModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_LANGUAGE_MODULE_NAME)
      }
    })

    const toast = useToast()
    const dataInfo = ref({projectId: router.currentRoute.params.id, images: null})
    const languageOptions = ref([])

    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,

      fetchList,
      reFetchData,
    } = useList()

    const onSubmit = (images) => {
      const formData = new FormData();
      for (var i = 0; i < images.length; i++) {
        let file = images[i];
        formData.append('files[' + i + ']', file);
      }

      formData.append('project_id', dataInfo.value.projectId);

      busy.value = true
      store.dispatch('store/addImages', formData).then(response => {
        toastMessage(toast, 'success', response.data.message)
        reFetchData()
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
      }).finally(message => {
        busy.value = false
      })

    };
    const selectFile = () => {
      document.getElementById('image_file').click()
    }
    const resetFile = () => {
      dataInfo.images = null
    }
    const inputImageRenderer = () => {
      onSubmit(document.getElementById('image_file').files)
    }

    const {refFormObserver, getValidationState, resetForm} = formValidation()

    busy.value = true
    store.dispatch('store-language/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.panel_status === 1) {
          languageOptions.value.push({label: value.name, value: value.id})
          if (value.main_language === true) {
            dataInfo.value.languageId = value.id
          }
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
      if (error.response.status === 403) {
        router.push({name: 'cms-project-list'})
      }
    }).finally(message => {
      busy.value = false
    })

    const selectedRecord = ref(null)
    const change_order_number = ref(0)
    const change_spaces_id = ref(null)
    const change_floor_material_id = ref(null)
    const change_features_id = ref(null)

    const changeSortNumberModalShow = ref(false)
    const changeSortNumberShowModal = (dataItem) => {
      change_order_number.value = dataItem.order_number
      selectedRecord.value = dataItem.id
      change_spaces_id.value = dataItem.spaces_id
      change_floor_material_id.value = dataItem.floor_material_id
      change_features_id.value = dataItem.features_id
      changeSortNumberModalShow.value = true
    }
    const handleChangeSortNumberModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      submitChangeSortNumberModal()
    }
    const submitChangeSortNumberModal = () => {
      if (change_order_number.value === null) {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'Sort number is required!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      busy.value = true
      store.dispatch('store/imageSorting', {
        id: selectedRecord.value,
        order_number: change_order_number.value,
        spaces_id: change_spaces_id.value,
        floor_material_id: change_floor_material_id.value,
        features_id: change_features_id.value
      }).then(response => {
        reFetchData()
        Vue.swal({
          icon: 'success',
          title: 'OK!',
          text: 'Saved.',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: error.response.data.message,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })

      changeSortNumberModalShow.value = false
    }

    const spaceOptions = ref([])
    busy.value = true
    store.dispatch('store/spaceItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.status === 1) {
          spaceOptions.value.push({label: value.name, value: value.id})
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
      if (error.response.status === 403) {
        router.push({name: 'cms-project-list'})
      }
    }).finally(message => {
      busy.value = false
    })

    const featureOptions = ref([])
    busy.value = true
    store.dispatch('store/featureItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.status === 1) {
          featureOptions.value.push({label: value.name, value: value.id})
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
      if (error.response.status === 403) {
        router.push({name: 'cms-project-list'})
      }
    }).finally(message => {
      busy.value = false
    })

    const floorMaterialOptions = ref([])
    busy.value = true
    store.dispatch('store/floorMaterialItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.status === 1) {
          floorMaterialOptions.value.push({label: value.name, value: value.id})
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
      if (error.response.status === 403) {
        router.push({name: 'cms-project-list'})
      }
    }).finally(message => {
      busy.value = false
    })

    return {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,

      busy,
      refFormObserver,

      dataInfo,
      languageOptions,

      onSubmit,
      selectFile,
      resetFile,
      inputImageRenderer,
      getValidationState,
      resetForm,
      getApiFile,
      fetchList,
      reFetchData,
      avatarText,

      change_order_number,
      change_features_id,
      change_floor_material_id,
      change_spaces_id,
      changeSortNumberModalShow,
      changeSortNumberShowModal,
      handleChangeSortNumberModal,
      submitChangeSortNumberModal,

      spaceOptions,
      floorMaterialOptions,
      featureOptions,
    };
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
